import * as React from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import SideText from "../components/SideText";
import SpaceHolder from "../components/Space";
import phil1 from "../assets/images/phil1.jpg";
import phil2 from "../assets/images/phil2.jpg";
import phil3 from "../assets/images/phil3.jpg";
import phil5 from "../assets/images/phil9.jpg";
import phil6 from "../assets/images/phil6.jpg";
import phil8 from "../assets/images/phil8.jpg";

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.color1};
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;
  top: -115px;
`;

const Wrapper = styled.div`
  max-width: 1400px;
  width: 95%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;

  h2 {
    color: ${(props) => props.theme.colors.color3};
    padding-bottom: 20px;
  }

  p {
    /* border: 1px dotted purple; */
    font-size: 1.1em;
    max-width: 900px;
    padding-bottom: 10px;
  }

  ul {
    max-width: 900px;
    padding: 0px 25px;
    font-size: 1.1em;

    ${(props) => props.theme.sizes.mobile} {
      padding: 0px 20px;
    }
    ${(props) => props.theme.sizes.tablet} {
      padding: 0px 20px;
    }
  }

  img {
    width: 35%;

    ${(props) => props.theme.sizes.mobile} {
      width: 85%;
    }
    ${(props) => props.theme.sizes.tablet} {
      width: 55%;
    }
    ${(props) => props.theme.sizes.laptop} {
      width: 40%;
    }
  }
`;

const WelcomeContainer = styled.div`
  background-color: ${(props) => props.theme.colors.color2};
  width: 100vw;
  position: relative;
  top: -225px;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  h1 {
    /* border: 1px solid ${(props) => props.theme.colors.cream}; */
    color: ${(props) => props.theme.colors.cream};
    padding: 50px;
    display: flex;
    width: 100%;

    ${(props) => props.theme.sizes.mobile} {
      width: 100%;
    }

    ${(props) => props.theme.sizes.tablet} {
      /* border: 1px solid orange; */
      width: 100%;
    }
    ${(props) => props.theme.sizes.laptop} {
      /* border: 1px solid green; */
      width: 50%;
    }
  }
`;

const IndexPage = () => {
  return (
    <>
      <PageLayout>
        <Navbar pageAt="philosophy" />
        <SpaceHolder height={80} />
        <SideText text="philosophy" />
        <WelcomeContainer>
          <h1>welcome to project essence.</h1>
        </WelcomeContainer>
        <Background>
          <Wrapper>
            <h2>purpose</h2>
            <p>
              so many of us often feel burnt out due to high-stress and
              fast-paced living. that's where we come to the rescue.
            </p>
            <p>
              project essence is here to provide you with a stolen moment of
              peace during a busy week. our beauty sanctuary has been created
              for active people, who crave tranquility, ease and great service.
              we take your regular 'getting my nails done' to the next level:
              blissful and restorative.
            </p>
            <SpaceHolder height={40} />

            {/* <img src={phil2}></img> */}
            <img src={phil3}></img>

            <SpaceHolder height={60} />
            <h2>inspiration</h2>
            <p>
              project essence is heavily influenced by Japandi idea: a mix of
              Japanese and Scandinavian design concepts. we focus on the notions
              of simplicity, connection with nature, and elevated comfort. as a
              result, our space has been curated with the thought of being
              aesthetically pleasing yet functional. our team strives to create
              an urban oasis that will relax and inspire our guests to embrace
              mindfulness and wellbeing, while having their nails look exquisite
              as they leave our boutique beauty lounge.
            </p>
            <SpaceHolder height={40} />
            <img src={phil8}></img>
            <SpaceHolder height={60} />

            <h2>project nails</h2>
            <p>
              our lovely nail technicians know how to create something that is
              so hard to find elsewhere: perfection.
            </p>

            <p>
              you can always rely on our masters' expertise and trust that they
              will use the best techniques to perform the manicure you deserve.
              all of our nail fairies are experts in e-filing: a procedure
              performed on dry skin, where our nail technicians use different
              e-bits to remove and exfoliate your cuticle, the skin itself
              (pedicure), and remove that old grown-out gel polish.
            </p>
            <SpaceHolder height={40} />
            <img src={phil5}></img>

            <SpaceHolder height={40} />
            <p>
              our ethos is to guarantee that your manicure or pedicure will be
              executed impeccably, and that includes prioritizing your safety
              above all. to learn more about our disinfection and sanitization
              protocols, visit our faq page or ask one of our team members next
              time you pay us a visit. quick note: we disinfect absolutely
              everything that touches a human hand in our salon and use one-time
              instruments (like nail files) when possible.
            </p>
            <p>
              we pride ourselves in making sure that your nail polish won't chip
              and will be applied right where the cuticle ends, and the cuticle
              itself won't come back too early. how do we know that? besides
              killer skills, our nail artists use only the finest tools (Stleks,
              Olton and U-tools) and gel polishes, such as Akzentz, Luxio, OPI,
              Komilfo, Kodi and other professional brands.
            </p>
            <SpaceHolder height={60} />
            <img src={phil6}></img>
            <SpaceHolder height={60} />
            <h2>brow theory</h2>
            <p>
              we all know that eyes are the windows to one's soul. however, have
              you considered the importance of tending to your 'soul window
              frame' – your <b>eyebrows</b>?
            </p>
            <p>
              your brows are one of the most prominent facial features. they
              play a crucial role in sculpting your face, enabling you to convey
              emotions, amplify your style, and influence your face's overall
              contour. brow architecture is intricately linked to your unique
              facial structure and physiology. opting for professional eyebrow
              care can yield a subtle yet remarkable enhancement to your overall
              appearance.
            </p>
            <p>
              maintaining well-groomed brows through professional care is
              paramount, as it can accentuate the beauty of your eyes, elevate
              your overall look with a refined touch, and even provide a
              *gentle* facelift effect, instantly revitalizing your appearance.
              so, <b>why trust us with your brows?</b> not only does our team
              boast years of experience and education, enabling us to craft a
              personalized approach for each guest, but we also love the natural
              look and truly believe that education and professional products in
              the hands of experts can create real-life magic.
            </p>
            <SpaceHolder height={20} />
            <p>
              whether your goal is to restore eyebrow balance, attain flawless
              shaping, or regain control over their growth, a professional
              eyebrow treatment at Project Essence offers a multitude of
              exceptional benefits:
            </p>
            <ul>
              <li>
                sculpting your brows to achieve a natural yet polished look can
                make your eyes appear more expansive, add dimension, and lend a
                youthful and refreshed appearance. we certainly know how to do
                just that<i> a low-maintenance brow routine</i>.
              </li>
              <SpaceHolder height={10} />
              <li>
                imagine the time you will save each morning when you opt for
                tinting and laminating your brows! hit that snooze button and
                embrace.
              </li>
              <SpaceHolder height={10} />
              <li>
                it is truly remarkable how much confidence people gain when
                discovering and maintaining their perfect brow shape, size, and
                color. we are dedicated to ensuring you leave our space
                empowered, delighted, and glowing. so, let's take the next step
                and schedule your brow appointment with Project Essence ASAP.
              </li>
            </ul>
            <SpaceHolder height={60} />
            <p>we know you will leave our space satisfied</p>
            <h2>let us prove it</h2>
          </Wrapper>
        </Background>
      </PageLayout>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Project Essence</title>;
